export const githubIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M12 0C5.374 0 0 5.373 0 12C0 17.302 3.438 21.8 8.207 23.387C8.806 23.498 9 23.126 9 22.81V20.576C5.662 21.302 4.967 19.16 4.967 19.16C4.421 17.773 3.634 17.404 3.634 17.404C2.545 16.659 3.717 16.675 3.717 16.675C4.922 16.759 5.556 17.912 5.556 17.912C6.626 19.746 8.363 19.216 9.048 18.909C9.155 18.134 9.466 17.604 9.81 17.305C7.145 17 4.343 15.971 4.343 11.374C4.343 10.063 4.812 8.993 5.579 8.153C5.455 7.85 5.044 6.629 5.696 4.977C5.696 4.977 6.704 4.655 8.997 6.207C9.954 5.941 10.98 5.808 12 5.803C13.02 5.808 14.047 5.941 15.006 6.207C17.297 4.655 18.303 4.977 18.303 4.977C18.956 6.63 18.545 7.851 18.421 8.153C19.191 8.993 19.656 10.064 19.656 11.374C19.656 15.983 16.849 16.998 14.177 17.295C14.607 17.667 15 18.397 15 19.517V22.81C15 23.129 15.192 23.504 15.801 23.386C20.566 21.797 24 17.3 24 12C24 5.373 18.627 0 12 0Z"
      fill="currentColor"
    />
  </svg>
);
export const twitterIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M24 4.55705C23.117 4.94905 22.168 5.21305 21.172 5.33205C22.189 4.72305 22.97 3.75805 23.337 2.60805C22.386 3.17205 21.332 3.58205 20.21 3.80305C19.313 2.84605 18.032 2.24805 16.616 2.24805C13.437 2.24805 11.101 5.21405 11.819 8.29305C7.728 8.08805 4.1 6.12805 1.671 3.14905C0.381 5.36205 1.002 8.25705 3.194 9.72305C2.388 9.69705 1.628 9.47605 0.965 9.10705C0.911 11.388 2.546 13.522 4.914 13.997C4.221 14.185 3.462 14.229 2.69 14.081C3.316 16.037 5.134 17.46 7.29 17.5C5.22 19.123 2.612 19.848 0 19.54C2.179 20.937 4.768 21.752 7.548 21.752C16.69 21.752 21.855 14.031 21.543 7.10605C22.505 6.41105 23.34 5.54405 24 4.55705Z"
      fill="currentColor"
    />
  </svg>
);
export const linkedIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M0 1.76041C0 0.815265 0.794438 0.0480957 1.77375 0.0480957H22.2262C23.2059 0.0480957 24 0.815265 24 1.76041V22.2401C24 23.1855 23.2059 23.9521 22.2262 23.9521H1.77375C0.794531 23.9521 0 23.1856 0 22.2403V1.76013V1.76041Z"
      fill="currentColor"
    />
    <path
      d="M7.29339 20.0527V9.291H3.70201V20.0527H7.29376H7.29339ZM5.49845 7.82193C6.75058 7.82193 7.53011 6.99556 7.53011 5.96283C7.50667 4.90658 6.75058 4.10327 5.52226 4.10327C4.29311 4.10327 3.49033 4.90658 3.49033 5.96274C3.49033 6.99547 4.26958 7.82184 5.47492 7.82184H5.49817L5.49845 7.82193ZM9.28126 20.0527H12.8724V14.0435C12.8724 13.7223 12.8958 13.4002 12.9907 13.1708C13.2502 12.5279 13.8411 11.8625 14.8334 11.8625C16.1326 11.8625 16.6526 12.8492 16.6526 14.2961V20.0527H20.2436V13.8822C20.2436 10.5769 18.4721 9.0387 16.1094 9.0387C14.1722 9.0387 13.3214 10.1171 12.8487 10.8516H12.8726V9.29137H9.28145C9.32833 10.3009 9.28117 20.053 9.28117 20.053L9.28126 20.0527Z"
      fill="white"
    />
  </svg>
);
export const discordIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.54 0C20.896 0 22 1.104 22 2.472V24L19.42 21.72L17.968 20.376L16.432 18.948L17.068 21.168H3.46C2.104 21.168 1 20.064 1 18.696V2.472C1 1.104 2.104 0 3.46 0L19.54 0ZM14.908 15.672C17.56 15.588 18.58 13.848 18.58 13.848C18.58 9.984 16.852 6.852 16.852 6.852C15.124 5.556 13.48 5.592 13.48 5.592L13.312 5.784C15.352 6.408 16.3 7.308 16.3 7.308C15.052 6.624 13.828 6.288 12.688 6.156C11.824 6.06 10.996 6.084 10.264 6.18L10.06 6.204C9.64 6.24 8.62 6.396 7.336 6.96C6.892 7.164 6.628 7.308 6.628 7.308C6.628 7.308 7.624 6.36 9.784 5.736L9.664 5.592C9.664 5.592 8.02 5.556 6.292 6.852C6.292 6.852 4.564 9.984 4.564 13.848C4.564 13.848 5.572 15.588 8.224 15.672C8.224 15.672 8.668 15.132 9.028 14.676C7.504 14.22 6.928 13.26 6.928 13.26L7.264 13.464L7.312 13.5L7.359 13.527L7.373 13.533L7.42 13.56C7.72 13.728 8.02 13.86 8.296 13.968C8.788 14.16 9.376 14.352 10.06 14.484C10.96 14.652 12.016 14.712 13.168 14.496C13.732 14.4 14.308 14.232 14.908 13.98C15.328 13.824 15.796 13.596 16.288 13.272C16.288 13.272 15.688 14.256 14.116 14.7C14.476 15.156 14.908 15.672 14.908 15.672ZM9.328 10.068C8.644 10.068 8.104 10.668 8.104 11.4C8.104 12.132 8.656 12.732 9.328 12.732C10.012 12.732 10.552 12.132 10.552 11.4C10.564 10.668 10.012 10.068 9.328 10.068ZM13.708 10.068C13.024 10.068 12.484 10.668 12.484 11.4C12.484 12.132 13.036 12.732 13.708 12.732C14.392 12.732 14.932 12.132 14.932 11.4C14.932 10.668 14.392 10.068 13.708 10.068Z"
      fill="currentColor"
    />
  </svg>
);
export const telegramIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.384 22.7789C18.706 23.0069 19.121 23.0639 19.491 22.9239C19.861 22.7829 20.133 22.4669 20.215 22.0839C21.084 17.9999 23.192 7.6629 23.983 3.9479C24.043 3.6679 23.943 3.3769 23.723 3.1899C23.503 3.0029 23.198 2.9489 22.926 3.0499C18.733 4.6019 5.81997 9.4469 0.541971 11.3999C0.206971 11.5239 -0.0110294 11.8459 -2.93924e-05 12.1989C0.0119706 12.5529 0.249971 12.8599 0.592971 12.9629C2.95997 13.6709 6.06697 14.6559 6.06697 14.6559C6.06697 14.6559 7.51897 19.0409 8.27597 21.2709C8.37097 21.5509 8.58997 21.7709 8.87897 21.8469C9.16697 21.9219 9.47497 21.8429 9.68997 21.6399C10.906 20.4919 12.786 18.7169 12.786 18.7169C12.786 18.7169 16.358 21.3359 18.384 22.7789ZM7.37397 14.1019L9.05297 19.6399L9.42597 16.1329C9.42597 16.1329 15.913 10.2819 19.611 6.9469C19.719 6.8489 19.734 6.6849 19.644 6.5699C19.555 6.4549 19.391 6.4279 19.268 6.5059C14.982 9.2429 7.37397 14.1019 7.37397 14.1019Z"
      fill="currentColor"
    />
  </svg>
);
export const tickIcon = (
  <svg viewBox="0 0 14 10" fill="none">
    <path
      d="M1.90039 4.8564L5.29639 8.2524L12.1004 1.46045"
      stroke="#F8F4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const lightIcon = (
  <svg viewBox="0 0 11 17" fill="none">
    <path
      d="M9.98769 8.11331C9.95648 8.00023 9.90175 7.89502 9.82708 7.80455C9.7524 7.71408 9.65947 7.64039 9.55436 7.58831L6.72936 6.18831L7.48769 0.946648C7.51262 0.764988 7.47694 0.580175 7.38618 0.42085C7.29542 0.261525 7.15465 0.136583 6.98567 0.0653853C6.81669 -0.00581245 6.62895 -0.0192904 6.45154 0.0270397C6.27413 0.0733698 6.11695 0.176921 6.00436 0.321648L0.171024 7.82165C0.0932681 7.92347 0.0400374 8.04185 0.0154676 8.16759C-0.00910217 8.29333 -0.00434904 8.42304 0.0293575 8.54665C0.0624587 8.67144 0.124103 8.78683 0.209427 8.88373C0.294751 8.98062 0.401421 9.05636 0.521024 9.10498L4.07936 10.53L3.32102 15.7133C3.29524 15.8994 3.33307 16.0887 3.42836 16.2505C3.52366 16.4124 3.67084 16.5373 3.84602 16.605C3.94368 16.6455 4.04866 16.6653 4.15436 16.6633C4.29161 16.6626 4.42657 16.628 4.54723 16.5626C4.6679 16.4972 4.77053 16.4029 4.84602 16.2883L9.84602 8.78831C9.91645 8.69243 9.96553 8.58259 9.98997 8.46616C10.0144 8.34973 10.0136 8.22942 9.98769 8.11331Z"
      fill="currentColor"
    />
  </svg>
);

export const closeIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M5.29372 5.29372C5.38659 5.20062 5.49692 5.12675 5.61839 5.07635C5.73985 5.02594 5.87006 5 6.00157 5C6.13307 5 6.26329 5.02594 6.38475 5.07635C6.50622 5.12675 6.61654 5.20062 6.70941 5.29372L12.0003 10.5866L17.2911 5.29372C17.3841 5.20077 17.4944 5.12703 17.6159 5.07672C17.7373 5.02642 17.8675 5.00052 17.999 5.00052C18.1304 5.00052 18.2606 5.02642 18.382 5.07672C18.5035 5.12703 18.6138 5.20077 18.7068 5.29372C18.7998 5.38668 18.8735 5.49703 18.9238 5.61849C18.9741 5.73994 19 5.87011 19 6.00157C19 6.13303 18.9741 6.2632 18.9238 6.38465C18.8735 6.50611 18.7998 6.61646 18.7068 6.70941L13.414 12.0003L18.7068 17.2911C18.7998 17.3841 18.8735 17.4944 18.9238 17.6159C18.9741 17.7373 19 17.8675 19 17.999C19 18.1304 18.9741 18.2606 18.9238 18.382C18.8735 18.5035 18.7998 18.6138 18.7068 18.7068C18.6138 18.7998 18.5035 18.8735 18.382 18.9238C18.2606 18.9741 18.1304 19 17.999 19C17.8675 19 17.7373 18.9741 17.6159 18.9238C17.4944 18.8735 17.3841 18.7998 17.2911 18.7068L12.0003 13.414L6.70941 18.7068C6.61646 18.7998 6.50611 18.8735 6.38465 18.9238C6.2632 18.9741 6.13303 19 6.00157 19C5.87011 19 5.73994 18.9741 5.61849 18.9238C5.49703 18.8735 5.38668 18.7998 5.29372 18.7068C5.20077 18.6138 5.12703 18.5035 5.07672 18.382C5.02642 18.2606 5.00052 18.1304 5.00052 17.999C5.00052 17.8675 5.02642 17.7373 5.07672 17.6159C5.12703 17.4944 5.20077 17.3841 5.29372 17.2911L10.5866 12.0003L5.29372 6.70941C5.20062 6.61654 5.12675 6.50622 5.07635 6.38475C5.02594 6.26329 5 6.13307 5 6.00157C5 5.87006 5.02594 5.73985 5.07635 5.61839C5.12675 5.49692 5.20062 5.38659 5.29372 5.29372Z"
      fill="currentColor"
    />
  </svg>
);
