export const teamList = [
  {
    id: "1",
    name: "Alexis Schlomer",
    prof: "Fudge Algorithm Chef",
    image: "/images/team/1.jpg",
  },
  {
    id: "2",
    name: "Emilien Duc",
    prof: "Cookie Recipe Architect",
    image: "/images/team/2.jpg",
  },
  {
    id: "3",
    name: "Michael Zuber",
    prof: "Secretary of Fudge",
    image: "/images/team/3.jpg",
  },
  {
    id: "4",
    name: "Sébastien Schlomer",
    prof: "Vault Keeper",
    image: "/images/team/4.jpg",
  },
  {
    id: "5",
    name: "Dimitri Cordenier",
    prof: "Cookware Specialist",
    image: "/images/team/5.jpg",
  },
];
export const roadmapList = [
  {
    id: "1",
    quarter: "Batch 1 (Q3 2023)",
    title: "Recipe Testing",
    text: ["Proof of Concept Bake-Off."],
    passed: true,
  },
  {
    id: "2",
    quarter: "Batch 2 (Q2 2024)",
    title: "Beta Tasting",
    text: [
      "Help us perfect the recipe! Join the trial bake on an EVM-compatible testnet.",
    ],
    passed: true,
  },
  {
    id: "3",
    quarter: "First Bake (Q4 2024)",
    title: "Launch feast (V1)",
    text: [
      "Dive into the fully-baked CryptoCookies V1, fresh out the oven!",
      "Mix things up with the Fudge Finance rollout on Ethereum.",
    ],
    passed: false,
  },
  {
    id: "4",
    quarter: "Double Batch (Q2 2025)",
    title: "Chef's Gala (V2)",
    text: [
      "More ingredients, more fun! CryptoCookies V2 is served.",
      "Smooth and rich Fudge Finance V1 goes live with an easy-to-scoop interface.",
    ],
    passed: false,
  },
];
export const featureList = [
  {
    id: "1",
    icon: "/images/feature/3.png",
    title: "Capital Protected",
    text: "Your money stays safe, only the generated yield is at play.",
  },
  {
    id: "2",
    icon: "/images/feature/2.png",
    title: "Compete for Yield",
    text: "Create winning strategies. Capture opponents’ yield. Conquer the  leaderboard.",
  },
  {
    id: "3",
    icon: "/images/feature/1.png",
    title: "Positive-Sum",
    text: "Real rewards founded on sustainable tokenomics.",
  },
];
export const stepsList = [
  {
    id: "1.",
    icon: "/images/map/1.png",
    text: "Collateral from users is staked on-chain in vaults.",
  },
  {
    id: "2.",
    icon: "/images/map/2.png",
    text: "Reserves fuel the FDG token.",
  },
  {
    id: "3.",
    icon: "/images/map/3.png",
    text: "Generated revenues are distributed to dApps.",
  },
  {
    id: "4.",
    icon: "/images/map/4.png",
    text: "dApps create demand for FDG and increase liquidity.",
  },
];
