import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import useMediaQuery from "./Base/Hooks/useMediaQuery";
import { AnimatePresence } from "framer-motion";
import Modal from "./Base/Modal";
import CookieBanner from "./Base/CookieBanner";

import usePageTracking from "./usePageTracking";

export default function App() {
  const [cookieBanner, setCookieBanner] = useState(false);

  useEffect(() => {
    if (cookieBanner) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [cookieBanner]);
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  const width = useMediaQuery();

  usePageTracking();

  return (
    <>
      <Header />
      <main className="wrapper">
        <Routes>
          <Route path="" element={<Home width={width} />} />
        </Routes>
      </main>
      <Footer />
      <AnimatePresence>
        {cookieBanner && (
          <Modal setModal={setCookieBanner}>
            <CookieBanner setModal={setCookieBanner} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
