import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import ReactGA from "react-ga4";

const container = document.getElementById("root");

ReactGA.initialize("G-KMRT0D3JY4");

ReactGA.send({
    hittype: "pageview",
    page: window.location.pathname + window.location.search
});


const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
