import React, { useRef } from 'react';
import Team from "./components/Team";
import Roadmap from "./components/Roadmap";
import Feature from "./components/Feature";
import Fudge from "./components/Fudge";
import Steps from "./components/Steps";
import Intro from "./components/Intro";

export default function Home({ width }) {
  const featuresRef = useRef(null);
  const fudgeRef = useRef(null);

  return (
    <>
      <Intro width={width} learnMoreRef={ featuresRef } />
      <Feature width={width} ref={ featuresRef } />
      <Fudge width={width} learnMoreRef={ fudgeRef }/>
      <Steps width={width} ref={ fudgeRef } />
      <Roadmap width={width} />
      <Team width={width} />
    </>
  );
}
